import Image from "next/image";
import type { Article } from "../../../BlogTypes";
import slugify from "slugify";
import Button from "./Button";

interface SmallBlogCardProps {
    title?: string;
    category: string;
    subcategory: string;
    img: string;
    slug: string;
    categorySlug?: string;
    subcategorySlug?: string;
    blog: Article;
}

export default function SmallBlogCard({
    title,
    category,
    subcategory,
    img,
    slug,
    categorySlug,
    subcategorySlug,
    blog,
}: SmallBlogCardProps) {
    return (
        <>
            <div className="min-w-[343px] lg:w-[404px] h-[480px] p-6 rounded-lg border-2 bg-white border-zinc-300 relative">
                <Image
                    className="lg:w-[404px] lg:h-64 min-w-89 min-h-40 max-h-64 rounded border border-zinc-300"
                    src={img}
                    alt=""
                    width={1200}
                    height={1200}
                    style={{ objectFit: "cover" }}
                />
                <div>
                    <div className=" text-neutral-800 text-2xl font-bold lg:leading-8 line-clamp-3">
                        {title}
                    </div>
                </div>
                <div className="absolute bottom-10">
                    <Button
                        as="a"
                        text="Zum Artikel"
                        backgroundColor="bg-white hover:bg-blue text-blue hover:text-white"
                        border="border border-blue"
                        textColor=""
                        href={`/de/${slugify(category, { lower: true })}/${slugify(
                            subcategory,
                            {
                                lower: true,
                            },
                        )}/${slug}`}
                    />
                </div>
            </div>
        </>
    );
}
