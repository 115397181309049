"use client";
import Image from "next/image";
import Button from "../Cards/Button";
import { MdButton } from "@/stories/Button.stories";
import SideBySideText from "../Text/SideBySideText";
import { sendGTMEvent } from "../Analytics";

interface LookingForHelpProps {
    isHomePage?: boolean;
}
export default function LookingForHelp({ isHomePage = false }: LookingForHelpProps) {
    return (
        <div className="relative">
            <div className={`${isHomePage ? "border border-gray-200 p-10" : ""}`}>
                <SideBySideText
                    backgroundColor="bg-white"
                    gaps="lg:gap-12"
                    elementLeft={
                        <div className="lg:w-1/2 space-y-8">
                            <div className="text-neutral-800 text-2xl lg:text-3xl font-bold leading-7">
                                Im online Kundenportal
                            </div>
                            <div className="text-black text-xl font-normal leading-7 lg:leading-6 -mt-3">
                                {isHomePage ? (
                                    <>
                                        Bestelle die Fernbehandlung für CHF 68.00 / EUR
                                        70.00
                                        <br />
                                        pro Monat ganz unkompliziert im online
                                        Kundenportal.
                                    </>
                                ) : (
                                    "Bestelle ganz unkompliziert im online Kundenportal."
                                )}
                            </div>

                            <div className="lg:flex lg:py-4 lg:space-x-5 space-y-5 lg:space-y-0">
                                <div className="flex justify-center w-full lg:w-auto lg:mr-5">
                                    <Button
                                        backgroundColor={"bg-blue"}
                                        size={"lg:w-[348px] w-full h-[60px]"}
                                        hasIcon={MdButton.args?.hasIcon}
                                        text="KONTO ERSTELLEN"
                                        paddings={"px-3 py-4"}
                                        rounded={MdButton.args?.rounded}
                                        textColor={MdButton.args?.textColor}
                                        textSize={MdButton.args?.textSize}
                                        as="a"
                                        href="https://app.rico-brunner.com/konto_erstellen"
                                        onClick={() => {
                                            sendGTMEvent({
                                                event: "cta_click",
                                                section_title: isHomePage
                                                    ? "Bereit deine innere Stärke zu finden?"
                                                    : "Hier erhältst du Unterstützung",
                                                section_index: isHomePage ? "8" : "1",
                                                cta_text: "KONTO ERSTELLEN",
                                                cta_link: `${process.env.NEXT_PUBLIC_URL}/de/masterclass#masterclassSection`,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="flex justify-center w-full lg:w-auto">
                                    <Button
                                        backgroundColor={`${
                                            isHomePage ? "bg-none" : "bg-blue"
                                        }`}
                                        size={"lg:w-[150px] w-full h-[60px]"}
                                        hasIcon={MdButton.args?.hasIcon}
                                        text="LOGIN"
                                        paddings={"px-3 py-4"}
                                        rounded={MdButton.args?.rounded}
                                        textColor={`${
                                            isHomePage
                                                ? "text-blue"
                                                : MdButton.args?.textColor
                                        }`}
                                        textSize={MdButton.args?.textSize}
                                        as="a"
                                        href="https://app.rico-brunner.com/"
                                        border={`${
                                            isHomePage ? "border border-blue" : ""
                                        }`}
                                        onClick={() => {
                                            sendGTMEvent({
                                                event: "cta_click",
                                                section_title: isHomePage
                                                    ? "Bereit deine innere Stärke zu finden?"
                                                    : "Hier erhältst du Unterstützung",
                                                section_index: isHomePage ? "8" : "1",
                                                cta_text: "Login",
                                                cta_link:
                                                    "https://app.rico-brunner.com/",
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    elementRight={
                        <div className="lg:w-1/2 space-y-8 lg:space-y-6">
                            <div className="text-neutral-800 text-2xl lg:text-3xl font-bold leading-7 sm:pt-4">
                                Ohne Login / Registration
                            </div>
                            <div className="text-black text-xl font-normal leading-7 lg:leading-8 -mt-3">
                                {isHomePage
                                    ? "Wenn du kein Konto anlegen möchtest, dann kannst du die Fernbehandlung für CHF 68.00 / EURO 70.00 pro Monat in wenigen Schritten über das."
                                    : "Wenn kein Konto anlegen möchtest, dann kannst du die Fernbehandlung in wenigen Schritten über das Bestellformular bestellen."}
                            </div>
                            <div className="flex lg:justify-start justify-center">
                                <Button
                                    backgroundColor={`${
                                        isHomePage ? "bg-blue" : "bg-none"
                                    }`}
                                    size={"lg:w-[348px] w-full h-[60px]"}
                                    hasIcon={MdButton.args?.hasIcon}
                                    text="zum bestellformular"
                                    paddings={"px-0 py-4"}
                                    rounded={MdButton.args?.rounded}
                                    textColor={`${
                                        isHomePage ? "text-white" : "text-blue"
                                    }`}
                                    textSize={MdButton.args?.textSize}
                                    as="a"
                                    href="/de/bestellung-fernbehandlung"
                                    border="border border-blue"
                                    onClick={() => {
                                        sendGTMEvent({
                                            event: "cta_click",
                                            section_title: isHomePage
                                                ? "Bereit deine innere Stärke zu finden?"
                                                : "Hier erhältst du Unterstützung",
                                            section_index: isHomePage ? "8" : "1",
                                            cta_text: "zum bestellformularn",
                                            cta_link: `${process.env.NEXT_PUBLIC_URL}/de/bestellung-fernbehandlung`,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    }
                    paddings={`${isHomePage ? "p-0 " : "py-10"}`}
                />

                <div
                    className={`lg:flex lg:justify-between ${
                        isHomePage ? "mt-4" : "mt-2"
                    }`}
                >
                    <div className="lg:w-1/2">
                        <div className="text-black text-xl font-bold leading-7 lg:leading-loose">
                            Bezahlmöglichkeiten
                        </div>
                        <div className="text-black text-xl font-normal leading-7 lg:leading-loose">
                            Per Rechnung oder direkt und unkompliziert online bezahlen
                        </div>
                        <Image
                            alt=""
                            width={500}
                            height={70}
                            src="/images/cardsJetsLg.png"
                        />
                    </div>
                    <div className="lg:w-1/2">
                        <div className="text-black text-xl font-bold leading-7 lg:leading-loose lg:px-8 sm:pt-4">
                            Bezahlmöglichkeit:
                        </div>
                        <div className="text-black text-xl font-normal leading-7 lg:leading-loose sm:pb-4 lg:px-8">
                            Hier kannst du per Rechnhung bezahlen
                        </div>
                    </div>
                </div>
            </div>
            {isHomePage && (
                <>
                    <div className="lg:absolute lg:block hidden -bottom-20 -right-10">
                        <Image
                            alt=""
                            width={280}
                            height={280}
                            src={"/images/yellowStamp.png"}
                        />
                    </div>
                    <div className="absolute lg:hidden -bottom-32 -right-3">
                        <Image
                            alt=""
                            width={200}
                            height={200}
                            src={"/images/yellowStamp.png"}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
