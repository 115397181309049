import { twMerge } from "tailwind-merge";
import TextColors from "./TextColors";

interface TitleProps {
    title?: string;
    text?: string;
    subTitle?: string;
    preTitle?: string;
    hasPreTitle?: boolean;
    hasSubTitle?: boolean;
    hasText?: boolean;
    textAlignment?: "left" | "center" | "right" | "";
    textColor?: string;
    paddings?: string;
    hasColorTitle?: boolean;
    differentTextColor?: string;
    wordsInDifferentColor?: string[];
    titleSize?: string;
    leadingTitle?: string;
    preTitleStyle?: string;
    preTitleSize?: string;
    wholeTitleAlignment?: string;
    subtitleStyle?: string;
    subtitleSize?: string;
    textWidth?: string;
    textColorSizes?: string;
}

export default function Title({
    title = "Ich schaue die Energiefelder an und repariere sie, wo nötig",
    text = " Seelische Verletzungen werden repariert, somit funktioniert das Energiefeld besser. So entsteht innere Stärke, um das im Leben zu verändern, was belastet.",
    subTitle = " Worum gehts: Gespräche können Klarheit schaffen",
    preTitle = " Worum geht’s: WIE FUNKTIONIERT DIE FERNBEHANDLUNG",
    hasPreTitle = true,
    hasSubTitle = true,
    hasText = true,
    textAlignment = "center",
    textColor = "text-black",
    paddings = "px-3 py-44 lg:px-64",
    hasColorTitle = false,
    differentTextColor,
    wordsInDifferentColor,
    titleSize = "text-5xl lg:text-8xl",
    leadingTitle = "leading-14 lg:leading-70",
    preTitleStyle = "uppercase font-normal",
    preTitleSize = "text-base lg:text-2xl",
    wholeTitleAlignment = "justify-center items-center",
    subtitleStyle = "font-normal italic leading-tight lg:leading-10",
    subtitleSize = " text-xl2 lg:text-xl3",
    textColorSizes = "text-5xl lg:text-8xl ",
    textWidth,
}: TitleProps) {
    return (
        <>
            <div
                className={twMerge(
                    "flex-col gap-6 inline-flex",
                    paddings,
                    wholeTitleAlignment,
                )}
            >
                {hasPreTitle && (
                    <div
                        className={twMerge(
                            `self-stretch text-${textAlignment} text-black leading-tight lg:leading-10`,
                            textColor,
                            preTitleStyle,
                            preTitleSize,
                        )}
                    >
                        {preTitle}
                    </div>
                )}
                {hasColorTitle ? (
                    <div className={`${paddings}`}>
                        <TextColors
                            differentTextColor={differentTextColor}
                            text={title}
                            textColor={textColor}
                            wordsInDifferentColor={wordsInDifferentColor}
                            textSizes={twMerge("font-bold", textColorSizes)}
                            paddings={paddings}
                        />
                    </div>
                ) : (
                    <>
                        <h1
                            className={twMerge(
                                `self-stretch lg:break-normal text-${textAlignment}  text-black font-bold`,
                                textColor,
                                titleSize,
                                leadingTitle,
                            )}
                        >
                            {title}
                        </h1>
                    </>
                )}
                {hasSubTitle && (
                    <div
                        className={twMerge(
                            `text-${textAlignment} text-black font-['Avenir Next Condensed'] `,
                            textColor,
                            subtitleSize,
                            subtitleStyle,
                        )}
                    >
                        {subTitle}
                    </div>
                )}
                {hasText && (
                    <div
                        className={twMerge(
                            `self-stretch text-${textAlignment} text-black text-lg lg:text-2xl  leading-6 lg:leading-9`,
                            textColor,
                            textWidth,
                        )}
                    >
                        {text}
                    </div>
                )}
            </div>
        </>
    );
}
