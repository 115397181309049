import { Group, Text, useMantineTheme } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { useRef, useState } from "react";

interface DropzoneProps {
    onFileUpload: (file: File) => void;
    submitFile: () => void;
}
export default function FileInput({ onFileUpload, submitFile }: DropzoneProps) {
    const theme = useMantineTheme();
    const openRef = useRef<(() => void) | null>(null);
    const [fileName, setFileName] = useState("");

    const handleFileDrop = (files: File[]) => {
        onFileUpload(files[0]);
        setFileName(files[0].name);
    };

    const removeFile = () => {
        onFileUpload(new File([], ""));
        setFileName("");
    };
    return (
        <>
            <Dropzone
                openRef={openRef}
                onDrop={handleFileDrop}
                radius="md"
                maxSize={25 * 1024 ** 2}
            >
                <div style={{ pointerEvents: "none" }}>
                    <Group justify="center">
                        <Dropzone.Accept>
                            <p>download</p>
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                            <p>X</p>
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                            <div>
                                <img src="/Icons/upload.svg" alt="" />
                            </div>
                        </Dropzone.Idle>
                    </Group>
                    {fileName ? (
                        <Text ta="center" fw={500} fz="lg" mt="xl">
                            {/* <span>{"uploadFile.uploadedFile"}</span> */}
                            <br />
                            <span>{fileName}</span>
                        </Text>
                    ) : (
                        <>
                            <Text ta="center" fw={700} fz="lg" mt="xl">
                                <Dropzone.Accept>Successfully uploaded</Dropzone.Accept>
                                <Dropzone.Reject>
                                    Datei zu groß, max. 25 MB erlaubt
                                </Dropzone.Reject>
                                <Dropzone.Idle>DATEI EINFÜGEN</Dropzone.Idle>
                            </Text>
                            <Text ta="center" fz="sm" mt="xs" c="dimmed" />
                        </>
                    )}
                </div>
            </Dropzone>
        </>
    );
}
