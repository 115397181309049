import { Group, Radio, Textarea } from "@mantine/core";
import ErrorModal from "../ErrorModal";
import InfoModal from "../Modal";
import Text from "../Text/Text";
import { ButtonDark } from "@/stories/Button.stories";
import Button from "../Cards/Button";
import FileInput from "../Inputs/FileInput";
import { TextInput } from "../Inputs/TextInput";
import { useState } from "react";
import { useForm } from "@mantine/form";

export default function FeedbackForm({
    openModal,
    closeModal,
    sendEmail,
}: {
    openModal: boolean;
    closeModal: () => void;
    sendEmail: (subject: string, data: object) => Promise<void>;
}) {
    const [file, setFile] = useState<File | null>(null);
    const [openThankyouModal, setopenThankyouModal] = useState(false);
    const [error, setError] = useState(false);
    const form = useForm({
        initialValues: {
            feedbackType: "",
            opinion: "",
            typeOfPublication: "",
            customerName: "",
            customerSurname: "",
            andere: "",
            email: "",
        },
    });

    return (
        <>
            <InfoModal onClose={() => closeModal()} opened={openModal} size="xl">
                <>
                    <Text
                        hasText={false}
                        hasPretitle={false}
                        hasSubTitle={false}
                        title="Hast du Lust, mir ein Feedback zu geben?"
                        subTitle="Wie möchtest du mir dein Feedback geben?"
                        paddings=""
                    />
                    <form
                        className="space-y-2 my-3"
                        onSubmit={form.onSubmit((values) => {
                            const formData = new FormData();
                            formData.append("file", file as Blob);
                            sendEmail("Feedback", { ...values, file: formData })
                                .then(() => {
                                    closeModal();
                                    setopenThankyouModal(true);
                                })
                                .catch(() => {
                                    <ErrorModal
                                        open={error}
                                        onClose={() => setError(false)}
                                    />;
                                });
                        })}
                    >
                        <Radio.Group
                            label="Wie möchtest du mir dein Feedback geben?"
                            id="feedbackType"
                            name="feedbackType"
                            {...form.getInputProps("feedbackType")}
                        >
                            <Group mt="xs">
                                <Radio
                                    value="text"
                                    label="Text Nachricht"
                                    className="text-black"
                                />
                                <Radio
                                    value="video"
                                    label="Video"
                                    className="text-black"
                                />
                                <Radio
                                    value="audio"
                                    label="Audio"
                                    className="text-black"
                                />
                            </Group>
                        </Radio.Group>

                        {form.values.feedbackType === "text" && (
                            <Textarea
                                id="opinion"
                                name="opinion"
                                label="Text Nachricht"
                                {...form.getInputProps("opinion")}
                            />
                        )}
                        {form.values.feedbackType === "video" && (
                            <FileInput onFileUpload={setFile} submitFile={console.log} />
                        )}
                        {form.values.feedbackType === "audio" && (
                            <FileInput onFileUpload={setFile} submitFile={console.log} />
                        )}
                        <Radio.Group
                            label="Art der Veröffentlichung"
                            id="typeOfPublication"
                            name="typeOfPublication"
                            {...form.getInputProps("typeOfPublication")}
                        >
                            <Group mt="xs">
                                <Radio
                                    id="anonym"
                                    name="anonym"
                                    value="anonym"
                                    label="Anonym"
                                    className="text-black"
                                />
                                <Radio
                                    value="name"
                                    label="Vor- und Nachname"
                                    name="name"
                                    id="name"
                                    className="text-black"
                                />
                                <Radio
                                    id="andereAngaben"
                                    name="andereAngaben"
                                    label="Andere Angaben"
                                    value="andereAngaben"
                                    className="text-black"
                                />
                            </Group>
                        </Radio.Group>
                        {form.values.typeOfPublication === "name" && (
                            <>
                                <TextInput
                                    id="customerName"
                                    name="customerName"
                                    label="Vorname"
                                    {...form.getInputProps("customerName")}
                                    type="text"
                                />
                                <TextInput
                                    id="customerSurname"
                                    name="customerSurname"
                                    label="Nachname"
                                    {...form.getInputProps("customerSurname")}
                                    type="text"
                                />
                                <TextInput
                                    id="email"
                                    name="email"
                                    label="Email"
                                    {...form.getInputProps("email")}
                                    type="email"
                                />
                            </>
                        )}
                        {form.values.typeOfPublication === "andereAngaben" && (
                            <>
                                <TextInput
                                    id="customerName"
                                    name="customerName"
                                    label="Vorname"
                                    {...form.getInputProps("customerName")}
                                    type="text"
                                />
                                <TextInput
                                    id="customerSurname"
                                    name="customerSurname"
                                    label="Nachname"
                                    {...form.getInputProps("customerSurname")}
                                    type="text"
                                />
                                <TextInput
                                    id="email"
                                    name="email"
                                    label="Email"
                                    {...form.getInputProps("email")}
                                    type="email"
                                />
                                <TextInput
                                    id="andere"
                                    name="andere"
                                    label="Andere Angaben"
                                    type="text"
                                    {...form.getInputProps("andere")}
                                />
                            </>
                        )}
                        <div className="flex py-5 justify-end">
                            <Button
                                backgroundColor={ButtonDark.args?.backgroundColor}
                                hasIcon={false}
                                textSize={ButtonDark.args?.textSize}
                                size={ButtonDark.args?.size}
                                paddings={ButtonDark.args?.paddings}
                                textColor={ButtonDark.args?.textColor}
                                text="Senden"
                                buttonType="submit"
                            />
                        </div>
                    </form>
                </>
            </InfoModal>
            <InfoModal
                opened={openThankyouModal}
                onClose={() => {
                    setopenThankyouModal(false);
                }}
                size="xl"
                paddings="px-10"
            >
                <>
                    <Text
                        title="Danke für dein Feedback!"
                        hasPretitle={false}
                        hasSubTitle={false}
                        hasText={true}
                        paddings=""
                        paragraphs={[
                            "Vielen Dank für deine Nachricht. Wir melden uns so schnell wie möglich bei dir.",
                            "Liebe  Grüsse",
                            "Rico Brunner",
                        ]}
                    />
                    <div className="flex py-5 justify-end">
                        <Button
                            backgroundColor={ButtonDark.args?.backgroundColor}
                            hasIcon={false}
                            textSize={ButtonDark.args?.textSize}
                            size={ButtonDark.args?.size}
                            paddings={ButtonDark.args?.paddings}
                            textColor={ButtonDark.args?.textColor}
                            text="Home"
                            as="a"
                            href="/"
                        />
                    </div>
                </>
            </InfoModal>
        </>
    );
}
