"use client";
import { SwiperSlide } from "swiper/react";
import Button from "../Cards/Button";
import Text from "../Text/Text";
import SliderNoArrows from "../Carousel/SliderNoArrows";
import { shuffle } from "@/utils";
import Testimonial from "../Text/Testimonial";
import Title from "../Text/Title";
import testimonials from "../../../public/content/testimonials.json";
import FeedbackForm from "./FeedbackForm";
import { useState } from "react";
import type { Article } from "../../../BlogTypes";
import Image from "next/image";
import SideBySideText from "../Text/SideBySideText";
import PlayerInPage from "../Player/PlayerInPage";
import Triangles from "../Triangles";
import LookingForHelp from "./LookingForHelp";
import FAQAccordion from "../FAQAccordion";
import CategoryMenu from "../CategoryMenu";
import SmallBlogCard from "../Cards/SmallBlogCard";
import FAQ from "../../../public/content/FAQ.json";
import { sendGTMEvent } from "../Analytics";

export default function HomeLayout({
    sendEmail,
    blogs,
}: {
    sendEmail: (subject: string, data: object) => Promise<void>;
    blogs: Article[];
}) {
    const [openModal, setopenModal] = useState(false);
    const remote_treatment_home = [
        {
            icon: "/Icons/home/light.svg",
            title: "Was ist meine Fernbehandlung",
            text: "In meiner Fernbehandlung identifiziere ich die Ursachen deines Unwohlseins und unterstütze dich beim Aufbau deiner emotionalen Stabilität. Denn wenn du emotional stark bist, können deine Symptome schwächer werden.",
            buttonLink: "/de/fernbehandlung/wie-sie-funktioniert",
        },

        {
            icon: "/Icons/home/lightning.svg",
            title: "Was ist meine Fernbehandlung NICHT",
            text: "Ich sende keine Energie und manipuliere keine Energiefelder! Fremde Energie stört den natürlichen Entwicklungsprozess und kann vom eigenen Energiefeld langfristig nicht interpretiert werden.",
            buttonLink: "/de/arbeitsweise/mechanische-grundsaetze",
        },
        {
            icon: "/Icons/home/crown.svg",
            title: "Dein Energiefeld ist mein Boss",
            text: "Dein Energiefeld gibt mir den Handlungsplan vor, indem es zeigt, wo und was getan werden muss. Es dient als Leitfaden für den Aufbau und die Stärkung der Energiefelder.",
            buttonLink: "/de/arbeitsweise/wie-funktionierts",
        },
    ];
    const list_home = [
        {
            icon: "/Icons/home/smiley.svg",
            title: "Du musst nichts tun, ich arbeite",
        },
        {
            icon: "/Icons/home/clock.svg",
            title: "Keine Wartezeit, starte jetzt gleich",
        },
        {
            icon: "/Icons/home/heart.svg",
            title: "Du bist ungebunden, kein Therapiestress",
        },
        {
            icon: "/Icons/home/sound.svg",
            title: "Du sprichst nicht über deine Probleme",
        },
        {
            icon: "/Icons/home/data.svg",
            title: "Volle Transparenz über monatliche Kosten",
        },
    ];
    const eventList_home = [
        {
            icon: "/Icons/home/data.svg",
            text: "Fr 21.03. - Sa 22.03.2025",
        },
        {
            icon: "/Icons/home/clock2.svg",
            text: "9.00 bis 16.00 Uhr",
        },
        {
            icon: "/Icons/home/location.svg",
            text: "Hotel Newstar, Breitfeldstrasse 9, 9015 St. Gallen",
        },
    ];
    const colorCards_home = [
        { text: "Du emotional stabil werden möchtest", color: "bg-yellow" },
        {
            text: "Dich Gespräche verwirren und dazu führen, dass deine Probleme komplexer werden",
            color: "bg-purple",
        },
        {
            text: "Deine Versuche, deine Probleme zu lösen, erfolglos sind",
            color: "bg-pink",
        },
        {
            text: "Wenn du dich mit deinen Problemen abgefunden hast",
            color: "bg-turquoise",
        },
    ];
    return (
        <>
            <div className="bg-white lg:px-20 flex  justify-center">
                <div className="">
                    <div className="justify-center items-end gap-6 lg:inline-flex">
                        <div>
                            <Title
                                textAlignment="left"
                                subtitleStyle="font-bold text-base leading-7 lg:leading-10"
                                title="Hallo, ich bin Rico Brunner"
                                hasSubTitle={true}
                                subTitle="Als Energiefeld-Therapeut biete ich seit 1998 Hilfe beim Aufbau emotionaler Stärke und Stabilität."
                                hasText={true}
                                hasPreTitle={false}
                                paddings="px-3 lg:pt-44 pt-30 "
                                text="Mit Erfahrung aus über 40.000 Einzelsitzungen konzentriere ich mich darauf, die Ursachen emotionaler und seelischer Verletzungen zu lösen. Denn durch den Aufbau innerer Stärke, können Symptome wie beispielsweise Burnout, Stress, Ängste, Hochsensibilität und Traumata gelindert werden."
                            />
                            <div className="py-8 px-3">
                                <Button
                                    text="Mehr zur Fernbehandlung"
                                    backgroundColor="bg-blue hover:bg-pink"
                                    hoverTransition="hover:ease-in duration-300 hover:scale-110"
                                    href="/de/fernbehandlung/warum-ich-sie-mache"
                                    as="a"
                                    paddings="px-6 lg:py-4 tablet:py-4 sm:py-4 px-2"
                                    margins="tablet:ml-2 sm:ml-2"
                                    size="h-15"
                                    onClick={() => {
                                        sendGTMEvent({
                                            event: "cta_click",
                                            section_title: "Mehr zur Fernbehandlung",
                                            section_index: "1",
                                            cta_text: "Mehr zur Fernbehandlung",
                                            cta_link: `${process.env.NEXT_PUBLIC_URL}/de/fernbehandlung/warum-ich-sie-mache`,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <Image
                                src={"/images/home/ricoHome.png"}
                                alt="Rico Brunner"
                                height={4000}
                                width={3500}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full lg:h-96 lg:mt-16 lg:m-auto bg-zinc-100 flex-col justify-center items-center gap-6 hidden lg:inline-flex">
                <Image
                    src={"/images/banner.png"}
                    width={1200}
                    height={1000}
                    alt=""
                    className="absolute lg:block hidden"
                />

                <div className="relative z-30 lg:top-10">
                    <div className="text-center text-white text-xl lg:text-5xl font-bold font-['Baton Turbo Web'] lg:leading-10">
                        Nächster Vortrag: Trauma lösen
                    </div>
                    <div className="text-center text-white  text-lg lg:text-2xl font-bold font-['Baton Turbo Web'] lg:leading-loose">
                        22. November 2024 um 20.00 Uhr, Hotel Einstein St. Gallen
                    </div>
                    <div className="text-center text-white text-lg lg:text-xl font-normal font-['Baton Turbo Web'] lg:leading-loose">
                        Tickets im Vorverkauf für CHF 35.- bis am 30.06.2024, danach CHF
                        39.-
                    </div>
                    <div className="flex justify-center items-center py-3">
                        <Button
                            backgroundColor="bg-turquoise"
                            textColor="text-black"
                            text="Jetzt Ticket sichern"
                            alignment="justify-center items-center"
                            as="a"
                            href="https://eventfrog.ch/de/p/fuehrungen-vortraege/vortrag/trauma-loesen-neue-anfaenge-und-mutige-spruenge-7196902334235909463.html"
                            newTab={true}
                            onClick={() => {
                                sendGTMEvent({
                                    event: "cta_click",
                                    section_title: " Nächster Vortrag: Trauma lösen",
                                    section_index: "2",
                                    cta_text: "Jetzt Ticket sichern",
                                    cta_link:
                                        "https://eventfrog.ch/de/p/fuehrungen-vortraege/vortrag/trauma-loesen-neue-anfaenge-und-mutige-spruenge-7196902334235909463.html",
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="lg:hidden block  bg-zinc-100 px-10 py-16">
                <div className="relative">
                    <div className="tablet:hidden block">
                        <Image
                            src={"/images/home/bannerPhone.png"}
                            width={900}
                            height={900}
                            alt=""
                        />
                    </div>
                    <div className="hidden tablet:block h-80 w-20">
                        <Image
                            src={"/images/banner.png"}
                            alt=""
                            fill={true}
                            style={{ objectFit: "cover" }}
                        />
                    </div>
                    <div className="absolute bottom-10 tablet:bottom-6 tablet:left-12 z-50 px-7">
                        <div className="text-center text-white text-xl3 font-bold  leading-10">
                            Nächster Vortrag: Trauma lösen
                        </div>
                        <div className="text-center text-white  text-xl font-bold leading-7">
                            22. November 2024 um 20.00 Uhr, Hotel Einstein St. Gallen
                        </div>
                        <div className="text-center text-white text-lg font-normal leading-7">
                            Tickets im Vorverkauf für CHF 35.- bis am 30.06.2024, danach
                            CHF 39.-
                        </div>
                        <div className="flex justify-center py-3">
                            <Button
                                backgroundColor="bg-turquoise"
                                textColor="text-black"
                                text="Jetzt Ticket sichern"
                                as="a"
                                href="https://eventfrog.ch/de/p/fuehrungen-vortraege/vortrag/trauma-loesen-neue-anfaenge-und-mutige-spruenge-7196902334235909463.html"
                                newTab={true}
                                paddings="py-3"
                                size="lg:w-fit md:w-fit w-full min-h-13"
                                onClick={() => {
                                    sendGTMEvent({
                                        event: "cta_click",
                                        section_title: " Nächster Vortrag: Trauma lösen",
                                        section_index: "2",
                                        cta_text: "Jetzt Ticket sichern",
                                        cta_link:
                                            "https://eventfrog.ch/de/p/fuehrungen-vortraege/vortrag/trauma-loesen-neue-anfaenge-und-mutige-spruenge-7196902334235909463.html",
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white container lg:py-10 px-3 py-16">
                <Title
                    hasColorTitle={false}
                    hasPreTitle={false}
                    hasSubTitle={false}
                    hasText={false}
                    title="Über Rico Brunner"
                    paddings=""
                    titleSize="lg:text-xl5 sm:text-xl4"
                />
                <SideBySideText
                    reverse={true}
                    gaps="gap-6"
                    paddings=""
                    backgroundColor="white"
                    elementRight={
                        <div className="space-y-2 pt-2">
                            <Text
                                paddings=""
                                hasPretitle={false}
                                hasReadMore={false}
                                hasSubTitle={false}
                                hasText={true}
                                hasTitle={true}
                                titleSize="lg:text-xl2 text-xl"
                                title="Menschen zu helfen, ihre innere Stärke zu entdecken ist Mittelpunkt meiner Arbeit"
                                paragraphs={[
                                    "Schon in meiner Kindheit entwickelte ich eine starke Wahrnehmung für Energien und fragte mich, warum es manchen Menschen gut geht und anderen nicht. Nach jahrelanger Forschung entdeckte ich, dass Symptome des Unwohlseins durch mangelnde innere Stärke und Stabilität entstehen. Diese Erkenntnis führte mich dazu, mit 26 Jahren meine Praxis zu gründen, um Menschen zu helfen, ihre eigene Kraft wiederzufinden.",
                                ]}
                                leading="leading-7 lg:leading-10"
                            />
                            <Button
                                backgroundColor="bg-blue hover:bg-black"
                                text="Erfahre mehr"
                                as="a"
                                href="/de/ueber-mich"
                                size="h-15"
                                paddings="py-4 px-6"
                                onClick={() => {
                                    sendGTMEvent({
                                        event: "cta_click",
                                        section_title: "Über Rico Brunner",
                                        section_index: "3",
                                        cta_text: "Erfahre mehr",
                                        cta_link: `${process.env.NEXT_PUBLIC_URL}/de/ueber-mich`,
                                    });
                                }}
                            />
                        </div>
                    }
                    elementLeft={
                        <div>
                            <PlayerInPage
                                videoId="4d262db03f1b8f7097c5e918a6fe2bcd"
                                thumbnail="/images/thumbnails/home/22-MeineGeschichte_Thumbnail_White.jpg"
                            />
                        </div>
                    }
                />
            </div>
            <div className="bg-white">
                <Triangles backgroundColor="bg-turquoise" buttomToTop={false} />
            </div>
            <div className="bg-turquoise w-full px-3 lg:px-16 py-40 flex-col justify-center items-center gap-6  inline-flex">
                <div className="container mx-auto">
                    <Text
                        paddings="lg:pb-4 "
                        title="Fernbehandlung"
                        subTitle="Meine Methode"
                        hasPretitle={false}
                        hasReadMore={false}
                        hasSubTitle={true}
                        hasText={false}
                        titleSize="lg:text-[60px] text-xl6 sm:text-xl4"
                        subTitleSize="lg:text-[32px] text-2xl"
                        titlePlacement="lg:gap-4"
                    />
                </div>
                <div className="lg:flex justify-between container space-x-4">
                    {remote_treatment_home.map((treatment) => (
                        <div className="lg:flex justify-between" key={treatment.title}>
                            <div className=" px-6">
                                <div className="flex justify-start">
                                    <Image
                                        src={treatment.icon}
                                        alt=""
                                        width={80}
                                        height={80}
                                    />
                                </div>
                                <div>
                                    <div className="text-black text-xl font-bold font-['Baton Turbo Web'] leading-loose pt-2">
                                        {treatment.title}
                                    </div>
                                    <div className="text-black text-xl font-normal font-['Baton Turbo Web'] leading-[34px] text-justify pt-4">
                                        {treatment.text}
                                    </div>
                                </div>
                                <div className="py-10 flex justify-start ">
                                    <Button
                                        backgroundColor="bg-white"
                                        text="Erfahre mehr"
                                        as="a"
                                        textColor="text-black"
                                        href={treatment.buttonLink}
                                        onClick={() => {
                                            sendGTMEvent({
                                                event: "cta_click",
                                                section_title: "Fernbehandlung",
                                                section_index: "4",
                                                cta_text: "Erfahre mehr",
                                                cta_link:
                                                    process.env.NEXT_PUBLIC_URL +
                                                    treatment.buttonLink,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="bg-turquoise">
                <Triangles backgroundColor="bg-white" buttomToTop={true} />
            </div>

            <div className="bg-white w-full px-3 lg:px-16 py-40 flex-col justify-center items-center gap-6  inline-flex">
                <div className="container">
                    <div className="flex lg:justify-center lg:items-center items-start">
                        <Title
                            hasColorTitle={false}
                            hasPreTitle={false}
                            hasSubTitle={false}
                            hasText={false}
                            title="Das sagen unsere Kunden"
                            paddings="px-3 lg:px-36"
                            titleSize="text-xl4 lg:text-8xl"
                            textAlignment="center"
                        />
                    </div>
                    <SliderNoArrows
                        paginationColor="#000"
                        contentEl={
                            <div className="flex justify-center items-center ">
                                {shuffle(testimonials?.testimonials)?.map(
                                    (testimonial) => {
                                        return (
                                            <SwiperSlide key={testimonial.testimonial}>
                                                <Testimonial
                                                    backgroundColor={"bg-while"}
                                                    hasImage={testimonial.hasImage}
                                                    text={testimonial?.testimonial}
                                                    icon={"/Icons/quoteIconBlack.svg"}
                                                    textColor={"text-black"}
                                                    name={testimonial?.name}
                                                    hasDescription={false}
                                                    wordsInDifferentColor={[""]}
                                                    hasReadMore={true}
                                                    differentTextColor=""
                                                />
                                            </SwiperSlide>
                                        );
                                    },
                                )}
                            </div>
                        }
                    />
                    <Text
                        title="Teile deine Erfahrung mit uns!"
                        paragraphs={[
                            "Du hast bereits Erfahrung mit Rico Brunner gemacht? Wir sind gespannt auf dein Feedback! Hinterlasse uns eine Bewertung und unterstütze uns dabei, besser zu werden.",
                        ]}
                        hasPretitle={false}
                        hasReadMore={false}
                        hasSubTitle={false}
                        hasText={true}
                        hasTitle={true}
                        paddings="px-3 lg:px-60 pt-10 pb-8 w-full md:px-24"
                        titleSize="lg:text-2xl text-xl"
                        textSize="lg:text-xl text-lg"
                        leading="lg:leading-7 leading-7"
                        leadingText="lg:leading-7 leading-7"
                        titlePlacement=""
                    />
                    <div className="flex justify-start lg:px-60">
                        <Button
                            text="Testimonial werden"
                            as="button"
                            backgroundColor="bg-blue hover:bg-black"
                            textColor="text-white"
                            size="h-15"
                            paddings="px-6 py-4"
                            hoverTransition="hover:ease-in duration-300 lg:hover:scale-110"
                            onClick={() => {
                                setopenModal(true);
                                sendGTMEvent({
                                    event: "cta_click",
                                    section_title: "Das sagen unsere Kunden",
                                    section_index: "5",
                                    cta_text: "Testimonial werden",
                                    cta_link: "modal",
                                });
                            }}
                        />
                    </div>
                    <FeedbackForm
                        sendEmail={sendEmail}
                        openModal={openModal}
                        closeModal={() => setopenModal(false)}
                    />
                </div>
            </div>

            <div className="bg-white w-full px-3 lg:px-16  pb-40  flex-col justify-center items-center gap-6 inline-flex">
                <div className="container">
                    <div className="flex justify-center items-center lg:justify-start lg:items-start">
                        <Text
                            title="Bereit deine innere Stärke zu finden?"
                            hasPretitle={false}
                            hasReadMore={false}
                            hasSubTitle={false}
                            hasText={false}
                            hasTitle={true}
                            titleSize="lg:text-xl3 text-xl2"
                            paddings="pb-2"
                            leading="leading-10"
                        />
                    </div>
                    <SideBySideText
                        paddings="lg:pb-25 pb-10 lg:-px-3"
                        backgroundColor="bg-white"
                        elementRight={
                            <>
                                <Text
                                    hasPretitle={false}
                                    hasReadMore={false}
                                    hasSubTitle={false}
                                    hasText={true}
                                    hasTitle={true}
                                    paragraphs={[
                                        "Nutze deine Stärke, um für dich und andere da zu sein. Mit einer Fernbehandlung kannst du deine emotionale Stabilität stärken und die Herausforderungen des Lebens meistern.",
                                        "Buche jetzt und entdecke, wie du dein volles Potenzial entfalten kannst!",
                                    ]}
                                    paddings="lg:px-10"
                                    title="Entdecke deine Kraft!"
                                    titleSize="lg:text-3xl text-2xl"
                                    titlePlacement=""
                                    leadingText="leading-7 lg:leading-[34px]"
                                />
                            </>
                        }
                        elementLeft={
                            <div className="flex lg:w-1/2 justify-center items-center">
                                <Image
                                    src={"/images/home/home.jpg"}
                                    alt=""
                                    width={500}
                                    height={500}
                                />
                            </div>
                        }
                    />
                    <div>
                        <LookingForHelp isHomePage={true} />
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <Triangles backgroundColor="bg-turquoise" buttomToTop={false} />
            </div>

            <div className="bg-turquoise w-full px-3  py-40 flex-col justify-center items-center gap-6  inline-flex">
                <div className="container ">
                    <div className="flex justify-start lg:py-0 pb-8 ">
                        <Title
                            title="Trauma erkennen und lösen:
                             Der Workshop"
                            subTitle="Praktische Hilfe bei Trauma: Übungen und Techniken für den Alltag"
                            hasColorTitle={false}
                            hasPreTitle={false}
                            hasSubTitle={true}
                            hasText={false}
                            textAlignment="left"
                            paddings="px-3  lg:pb-10"
                            titleSize="lg:text-[60px] text-xl5"
                            subtitleSize="lg:text-xl text-2xl"
                            subtitleStyle="font-bold"
                            leadingTitle="sm:leading-14 lg:leading-50"
                            wholeTitleAlignment="justify-start items-start"
                        />
                    </div>
                    <div className="tablet:mx-44">
                        <div className="bg-white rounded-lg  w-full lg:flex p-3 lg:p-10 justify-between space-y-4 lg:space-x-4">
                            <div className="space-y-4  ">
                                <div className="space-y-4 lg:space-y-0">
                                    <Text
                                        hasPretitle={false}
                                        hasReadMore={false}
                                        hasSubTitle={false}
                                        hasText={true}
                                        hasTitle={false}
                                        paragraphs={[
                                            "Dieser Workshop vermittelt durch praxisnahe Beispiele und Übungen wertvolle Techniken, die dir helfen, besser mit Traumafolgen umzugehen. Du erhältst Werkzeuge, die du direkt anwenden kannst, sei es als Betroffene, Angehörige oder Therapeutin.",
                                        ]}
                                        paddings=""
                                        textColor="text-neutral-800"
                                        leading="leading-g lg:leading-10"
                                    />
                                    <div className="">
                                        {eventList_home.map((item, index) => (
                                            <div
                                                className="flex space-x-2"
                                                key={item.text}
                                            >
                                                <Image
                                                    src={item.icon}
                                                    alt=""
                                                    width={20}
                                                    height={20}
                                                />
                                                <p className="text-black text-xl1 font-bold lg:leading-loose leading-7 ">
                                                    {item.text}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    <p className="text-xl1 font-bold">
                                        CHF <span className="text-xl3">640.00</span>
                                    </p>
                                </div>
                                <Button
                                    as="a"
                                    backgroundColor="bg-turquoise"
                                    text="JETZT BUCHEN"
                                    textColor="text-black"
                                    alignment="justify-center items-center"
                                    href="/de/anmeldung-formular"
                                    onClick={() => {
                                        sendGTMEvent({
                                            event: "cta_click",
                                            section_title:
                                                "Trauma erkennen und lösen: Der Workshop",
                                            section_index: "7",
                                            cta_text: "JETZT BUCHEN",
                                            cta_link: `${process.env.NEXT_PUBLIC_URL}/de/anmeldung-formular`,
                                        });
                                    }}
                                />
                            </div>
                            <div className="flex  justify-center  items-center">
                                <Image
                                    src={"/images/home/ricoEvent.png"}
                                    alt=""
                                    width={1000}
                                    height={1000}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-turquoise">
                <Triangles backgroundColor="bg-yellow" buttomToTop={true} />
            </div>

            <div className="bg-yellow w-full py-36 flex-col gap-6 inline-flex">
                <div className="container">
                    <div className="flex justify-center items-center lg:justify-start lg:items-start">
                        <Text
                            title="Die STÄRKE eines ausgeglichenen Energiefeldes"
                            hasPretitle={false}
                            hasReadMore={false}
                            hasSubTitle={false}
                            hasText={false}
                            hasTitle={true}
                            titleSize="lg:text-5xl text-3xl"
                            paddings="px-3"
                            leading="leading-10"
                        />
                    </div>
                    <SideBySideText
                        paddings=" py-4 px-3"
                        elementRight={
                            <>
                                <Text
                                    hasPretitle={false}
                                    hasReadMore={false}
                                    hasSubTitle={false}
                                    hasText={true}
                                    hasTitle={false}
                                    paragraphs={[
                                        "Viele Probleme des Unwohlseins bei Menschen resultieren aus einem unausgeglichenen Energiefeld. Durch die Reparatur dieses Energiefeldes und der Stärkung der emotionalen Stabilität können Symptome gelindert und die Vitalität verbessert werden.",
                                    ]}
                                    paddings="px-4"
                                    textColor="text-white"
                                    textAlignment="lg:text-justify sm:text-left"
                                    textSize="lg:text-xl3 sm:text-xl2"
                                    textWeight="font-semibold"
                                    leading="leading-10"
                                />
                            </>
                        }
                        elementLeft={
                            <div className="flex justify-center items-center  h-fit w-fit`">
                                <PlayerInPage
                                    videoId="667cfbb0dbcf60c8dacc772c0717fa5e"
                                    thumbnail="/images/thumbnails/home/DefekteEnergiefelder.png"
                                    darkMode={true}
                                />
                            </div>
                        }
                    />
                </div>
            </div>
            <div className="bg-yellow">
                <Triangles backgroundColor="bg-yellow" buttomToTop={false} />
            </div>

            <div className="bg-yellow">
                <Triangles backgroundColor="bg-turquoise" buttomToTop={false} />
            </div>

            <div className="bg-turquoise w-full px-3 lg:px-16 py-36 flex-col justify-center items-center gap-6 inline-flex">
                <div className="container">
                    <div className="flex lg:justify-left lg:items-left justify-center items-center">
                        <Title
                            hasColorTitle={false}
                            hasPreTitle={false}
                            hasSubTitle={false}
                            hasText={false}
                            title="Vorteile der Fernbehanldung"
                            paddings="px-3 pb-2"
                            textAlignment="center"
                            titleSize="text-4xl lg:text-8xl"
                        />
                    </div>
                    <SideBySideText
                        backgroundColor="bg-turquoise"
                        paddings="py-5 px-3 md:px-0 "
                        elementLeft={
                            <div className="flex justify-center items-center lg:justify-start lg:items-start">
                                <Image
                                    src={"/images/home/RicoHome2.png"}
                                    alt=""
                                    width={600}
                                    height={600}
                                />
                            </div>
                        }
                        elementRight={
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex">
                                {list_home.map((item, index) => (
                                    <div className="flex space-x-2" key={item.icon}>
                                        <Image
                                            src={item.icon}
                                            alt=""
                                            width={30}
                                            height={30}
                                        />
                                        <p className="text-black lg:text-2xl text-lg font-bold leading-relaxed">
                                            {item.title}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        }
                    />
                </div>
            </div>
            <div className="bg-turquoise">
                <Triangles backgroundColor="bg-white" buttomToTop={true} />
            </div>
            <div className="bg-white w-full px-3 lg:pt-36 pt-25  flex-col  gap-6 inline-flex">
                <div className="container">
                    <div className="flex justify-start items-start">
                        <Title
                            title="Die Fernbehandlung passt zu dir, wenn..."
                            hasColorTitle={false}
                            hasPreTitle={false}
                            hasSubTitle={false}
                            hasText={false}
                            textAlignment="left"
                            paddings=""
                            titleSize="lg:text-xl4 text-xl3"
                            wholeTitleAlignment="justify-start items-start"
                            leadingTitle="leading-10"
                        />
                    </div>
                    <div className="lg:flex text-center mx-auto pt-10 lg:pt-10 lg:justify-between space-y-4 lg:space-y-0 lg:space-x-4">
                        {colorCards_home.map((item, index) => (
                            <div
                                className={`lg:w-80 w-full h-[132px] lg:h-56 ${item.color} lg:p-10 p-6 text-center rounded-lg flex justify-center items-center`}
                                key={item.color}
                            >
                                <p className="text-black text-xl1 font-bold">
                                    {item.text}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center items-center py-10">
                        <Button
                            as="a"
                            text="Mehr erfahren"
                            backgroundColor="bg-blue hover:bg-black "
                            hoverTransition=" hover:scale-110 hover:ease-in duration-300"
                            href="/de/fernbehandlung/jetzt-buchen"
                            onClick={() => {
                                sendGTMEvent({
                                    event: "cta_click",
                                    section_title:
                                        "Die Fernbehandlung passt zu dir, wenn...",
                                    section_index: "10",
                                    cta_text: "Mehr erfahren",
                                    cta_link: `${process.env.NEXT_PUBLIC_URL}/de/fernbehandlung/jetzt-buchen`,
                                });
                            }}
                        />
                    </div>
                    <div className="bg-white space-y-2 w-full  ">
                        <div className="lg:pb-4">
                            <div className="text-center text-black text-xl7 lg:text-xl6 sm:text-xl4 font-bold  leading-7 lg:leading-14 pb-5">
                                FAQ
                            </div>

                            <div className="text-center text-black lg:text-xl1 text-lg font-normal ">
                                Hast du noch Fragen zur Fernbehandlung?
                            </div>
                            <div className="text-center text-black lg:text-xl1 text-lg font-normal pb-2">
                                Kein Problem, wir haben die Antworten für dich!
                            </div>
                        </div>

                        <div className="pb-5">
                            <FAQAccordion
                                isHomePage={true}
                                // @ts-ignore
                                FAQItem={
                                    FAQ.FAQ.find((item) => item.page === "Home")?.FAQ
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-neutral-100 bg-opacity-70 w-full py-25">
                <div className="container">
                    <div className="lg:flex lg:pt-10 px-3 justify-between">
                        <a
                            href="/de/alle-blogs"
                            className="text-black text-5xl font-bold leading-14 cursor-pointer pb-2"
                        >
                            Neuste Blogbeiträge
                        </a>
                        <div className="flex lg:justify-center justify-start items-start  ">
                            <CategoryMenu />
                        </div>
                    </div>
                    <div>
                        <Text
                            hasPretitle={false}
                            hasReadMore={false}
                            hasSubTitle={false}
                            hasText={true}
                            hasTitle={false}
                            paragraphs={[
                                "In meinen Blogs berichte ich von echten Praxiserfahrungen, gebe Tipps und erkläre die Wechselwirkungen zwischen Symptomen wie Burnout, Hochsensibilität, Depressionen und deren Ursachen. ",
                            ]}
                            paddings="py-5 px-3"
                        />
                    </div>
                    <div className="lg:flex lg:flex-row flex flex-col lg:justify-between  jusetify-center items-center space-y-4 container mx-auto lg:space-y-0 lg:px-14">
                        {blogs?.map((blog: Article) => (
                            <SmallBlogCard
                                key={blog.id}
                                blog={blog}
                                category={blog.category}
                                img={blog.cover_media?.public_url}
                                slug={blog.slug}
                                subcategory={blog.subcategories[0]}
                                categorySlug={blog.category_slug}
                                subcategorySlug={blog.subcategories_slug[0]}
                                title={blog.title}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
